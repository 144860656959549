import React from 'react';
import styled from 'styled-components';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <CloseButton onClick={toggleSidebar}>X</CloseButton>
      <SidebarTitle>Pulse Bar</SidebarTitle>
      <SidebarContent>
        <SidebarItem>Item 1</SidebarItem>
        <SidebarItem>Item 2</SidebarItem>
        <SidebarItem>Item 3</SidebarItem>
        {/* Add more items as needed */}
      </SidebarContent>
    </SidebarContainer>
  );
};

export default Sidebar;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${props => (props.isOpen ? '0' : '-250px')};
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  transition: right 0.3s ease;
  box-shadow: ${props => (props.isOpen ? '-2px 0 5px rgba(0,0,0,0.5)' : 'none')};
  z-index: 1000;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const SidebarTitle = styled.h1`
  margin: 0;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #555;
`;

const SidebarContent = styled.div`
  padding: 20px;
`;

const SidebarItem = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #555;
`;
