import React from 'react';
import styled from 'styled-components';

const EventCellRenderer = ({ data }) => {
    const event = data.event;

    if (!event) {
        return null;
    }

    const { commence_time } = event;
    

    return (
        <Container>
            <EventTime>{new Date(commence_time).toLocaleString()}</EventTime>
        </Container>
    );
};

export default EventCellRenderer;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const EventTime = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

