import React, { useEffect, useMemo, useState, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './ag-grid-theme-builder.css'; // Import custom dark theme CSS
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OddsCellRenderer from './OddsCellRenderer';
import TeamCellRenderer from './TeamCellRenderer';
import EventCellRenderer from './EventCellRenderer';
import PlayerPropsCellRenderer from './PlayerPropsCellRenderer';

const LogoHeader = ({ logoPath }) => (
    <div style={{ paddingLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <img src={logoPath} alt="Logo" style={{ height: '35px', borderRadius:'5px', alignContent:'center', alignItems:'center' }} />
    </div>
);

const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
    };
};

const PlayerProps = ({ sportKey = '' }) => {
    const [props, setProps] = useState([]);
    const [marketTypes, setMarketTypes] = useState([]);
    const [bookmakers, setBookmakers] = useState([]);
    const [marketFilter, setMarketFilter] = useState('');
    const [bookmakerFilter, setBookmakerFilter] = useState([]);

    useEffect(() => {
        if (!sportKey) return;

        const fetchProps = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/odds/${sportKey}/player`);
                setProps(response.data);
                extractMarketTypesAndBookmakers(response.data);
            } catch (error) {
                console.error('Error fetching player props:', error);
            }
        };

        fetchProps();
        const intervalId = setInterval(fetchProps, 5000);

        return () => clearInterval(intervalId);
    }, [sportKey]);

    const extractMarketTypesAndBookmakers = (data) => {
        const marketTypesSet = new Set();
        const bookmakersSet = new Set();
        data.forEach(eventItem => {
            if (eventItem.eventPlayerProps?.bookmakers) {
                eventItem.eventPlayerProps.bookmakers.forEach(bookmaker => {
                    bookmakersSet.add(bookmaker.key);
                    if (bookmaker.markets) {
                        bookmaker.markets.forEach(market => {
                            marketTypesSet.add(market.key);
                        });
                    }
                });
            }
        });
        const marketTypesArray = [...marketTypesSet];
        const bookmakersArray = [...bookmakersSet].map(bm => ({ value: bm, label: bm }));
        setMarketTypes(marketTypesArray);
        setBookmakers(bookmakersArray);
        setMarketFilter(prev => prev || (marketTypesArray.length > 0 ? marketTypesArray[0] : ''));
    };

    const handleMarketFilterChange = debounce((value) => {
        setMarketFilter(value);
    }, 300);

    const handleBookmakerFilterChange = (value) => {
        setBookmakerFilter(value || []);
    };

    const filterByMarketAndBookmaker = useCallback((propsItem) => {
        if (marketFilter === '' && bookmakerFilter.length === 0) return true;
        const selectedBookmakers = bookmakerFilter.map(bm => bm.value);
        return propsItem.eventPlayerProps?.bookmakers?.some(bookmaker =>
            (bookmakerFilter.length === 0 || selectedBookmakers.includes(bookmaker.key)) &&
            bookmaker.markets?.some(market => market.key === marketFilter)
        );
    }, [marketFilter, bookmakerFilter]);

    const columns = useMemo(() => {
        if (props.length === 0) {
            return [];
        }

        const commonColumns = [
            {   
                headerName: 'Event',
                field: 'event',
                valueGetter: params => params.data.event || {}, // Ensure correct structure
                cellRenderer: 'EventCellRenderer',
                maxWidth: 300,
            },
            {
                headerName: 'Teams',
                field: 'event',
                valueGetter: params => params.data.event || {}, // Ensure correct structure
                cellRenderer: 'TeamCellRenderer',
                maxWidth: 200,
            },
            {
                headerName: 'Player',
                field: 'player',
                valueGetter: params => params.data.player || '', // Get player directly
                cellRenderer: params => params.value,
                maxWidth: 200,
            },
            {
                headerName: 'Outcome',
                maxWidth: 200,
                field: 'outcome',
                cellRenderer: params => {
                    const outcome = params.data.outcome;
                    const event = params.data.event || {};
                    const eventPlayerProps = params.data.eventPlayerProps || {};

                    // Extracting the necessary values
                    const bookmaker = eventPlayerProps.bookmakers?.find(bm => bm.markets.some(m => m.outcomes.some(out => out.name === params.data.player && out.description === outcome)));
                    const market = bookmaker?.markets.find(m => m.outcomes.some(out => out.name === params.data.player && out.description === outcome));
                    const marketKey = marketFilter; // Use marketFilter for the link
                    const outcomeDescription = market?.outcomes.find(out => out.name === params.data.player && out.description === outcome)?.description || 'null';

                    // Create the Link with all necessary parameters
                    return (
                        <Link to={`/props-history/${event.id}/${marketKey}/${bookmaker?.key || 'null'}/${params.data.player || 'null'}/${outcomeDescription}`}>
                            {outcome}
                        </Link>
                    );
                }
            },
       
        ];

        const selectedBookmakers = bookmakerFilter.map(bm => bm.value);
        const filteredBookmakers = props[0]?.eventPlayerProps?.bookmakers?.filter(bookmaker =>
            bookmakerFilter.length === 0 || selectedBookmakers.includes(bookmaker.key)
        ) || [];

        const bookmakerColumns = filteredBookmakers.map(bookmaker => ({
            headerComponent: LogoHeader,
            // eslint-disable-next-line
            headerComponentParams: { logoPath: `/logos/${bookmaker.key}_logo.png` || '/logos/${bookmaker.key}_logo.jpeg'},
            maxWidth: 125,
            field: `outcomes.${bookmaker.key}`,
            valueGetter: params => {
                const outcomes = params.data.eventPlayerProps?.bookmakers?.find(bm => bm.key === bookmaker.key)?.markets?.find(market => market.key === marketFilter)?.outcomes;
                if (!outcomes) return '';

                // Dynamically construct the value object for PlayerPropsCellRenderer
                const value = outcomes.map(outcome => {
                    const type = outcome.name === 'Over' ? 'o' : 'u';
                    return `${outcome.price} (${type}${outcome.point})`;
                }).join(', ');

                return value;
            },
            cellRenderer: 'PlayerPropsCellRenderer',
        }));
        // eslint-disable-next-line
        return [...commonColumns, ...bookmakerColumns];
        // eslint-disable-next-line
    }, [props, marketFilter, bookmakerFilter, sportKey]);

    const rowData = useMemo(() => {
        const rows = [];

        props.filter(filterByMarketAndBookmaker).forEach(propsItem => {
            propsItem.eventPlayerProps?.bookmakers.forEach(bookmaker => {
                bookmaker.markets.forEach(market => {
                    if (market.key === marketFilter) {
                        market.outcomes.forEach(outcome => {
                            rows.push({
                                event: propsItem.event,
                                eventPlayerProps: propsItem.eventPlayerProps,
                                player: outcome.name,
                                outcome: outcome.description,
                                outcomes: {
                                    [bookmaker.key]: outcome.price,
                                    [`${bookmaker.key}_points`]: outcome.point
                                }
                            });
                        });
                    }
                });
            });
        });

        return rows;
    }, [props, marketFilter, filterByMarketAndBookmaker]);

    return (
        <Container>
            <Header>
                <h2>{sportKey.toUpperCase()} Player Props</h2>
                <FilterContainer>
                    <select value={marketFilter} onChange={(e) => handleMarketFilterChange(e.target.value)}>
                        {marketTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                    <Select
                        isMulti
                        value={bookmakerFilter}
                        onChange={handleBookmakerFilterChange}
                        options={bookmakers}
                        placeholder="Select Bookmakers"
                        styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                    />
                </FilterContainer>
            </Header>
            <div className="ag-theme-custom" style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                    components={{ OddsCellRenderer, TeamCellRenderer, EventCellRenderer, PlayerPropsCellRenderer }} // Add renderers
                    rowData={rowData}
                    columnDefs={columns}
                    rowHeight={100} // Set static row height here
                    domLayout='autoHeight'
                    suppressMovableColumns={true}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        sortable: true,
                        resizable: true,
                        filter: true, // Enable default filters
                    }}
                />
            </div>
        </Container>
    );
};

PlayerProps.propTypes = {
    sportKey: PropTypes.string.isRequired,
};

export default PlayerProps;

const Container = styled.div`
    padding: 20px;
    background-color: #1F2836;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 2px solid #333;
`;

const FilterContainer = styled.div`
    display: flex;
    gap: 10px;
`;
// eslint-disable-next-line
const StyledButton = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #0056b3;
        transform: scale(1.05);
    }

    &:active {
        background-color: #004085;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
    }

    &:disabled {
        background-color: #6c757d;
        cursor: not-allowed;
    }
`;
