import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { createChart } from 'lightweight-charts';

const OddsHistoryChart = () => {
    const { eventId, marketKey, bookmakerKey = '', outcomeName = '', outcomeDescription = '' } = useParams();
    const [data, setData] = useState([]);
    const [bookmakers, setBookmakers] = useState([]);
    const [outcomeNames, setOutcomeNames] = useState([]);
    const [outcomeDescriptions, setOutcomeDescriptions] = useState([]);
    const [selectedBookmaker, setSelectedBookmaker] = useState({ value: bookmakerKey, label: bookmakerKey });
    const [selectedOutcomeName, setSelectedOutcomeName] = useState({ value: outcomeName, label: outcomeName });
    const [selectedOutcomeDescription, setSelectedOutcomeDescription] = useState({ value: outcomeDescription, label: outcomeDescription || 'None' });
    const chartContainerRef = useRef();
    const chartRef = useRef();
    const lineSeriesRef = useRef();

    useEffect(() => {
        console.log('URL Parameters:', { eventId, marketKey, bookmakerKey, outcomeName, outcomeDescription });

        const fetchData = async () => {
            const selectedBookmakerKey = selectedBookmaker?.value || 'null';
            const selectedOutcomeNameValue = selectedOutcomeName?.value || 'null';
            const selectedOutcomeDescriptionValue = selectedOutcomeDescription?.value === 'None' ? 'null' : selectedOutcomeDescription?.value || 'null';

            const url = `${process.env.REACT_APP_BACKEND_URL}/api/odds/history/${eventId}/${marketKey}?bookmakerKey=${selectedBookmakerKey}&outcomeName=${selectedOutcomeNameValue}&outcomeDescription=${selectedOutcomeDescriptionValue}`;
            console.log('Fetching data from URL:', url);
            try {
                const response = await axios.get(url);
                console.log('Fetched data:', response.data);

                // Extract odds and filter data
                const { odds, filters } = response.data;
                const sortedData = odds.slice().sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                // Filter out duplicate timestamps and convert odds to numbers
                const uniqueSortedData = sortedData.filter((item, index, self) =>
                    index === 0 || new Date(item.timestamp).getTime() !== new Date(self[index - 1].timestamp).getTime()
                ).map(item => ({
                    ...item,
                    odds: Number(item.odds)  // Convert odds to number
                }));

                // Use Set to ensure unique outcome descriptions
                const uniqueOutcomeDescriptions = [...new Set(uniqueSortedData.map(d => d.outcome_description))];

                setData(uniqueSortedData);
                setBookmakers(filters.bookmakers.map(bm => ({ value: bm, label: bm })));
                setOutcomeNames(filters.outcomeNames.map(on => ({ value: on, label: on })));
                setOutcomeDescriptions([{ value: 'None', label: 'None' }, ...uniqueOutcomeDescriptions.map(od => ({ value: od, label: od }))]);
            } catch (error) {
                console.error('Error fetching historical odds:', error);
            }
        };
        fetchData();
    }, [eventId, marketKey, selectedBookmaker, selectedOutcomeName, selectedOutcomeDescription]);

    useEffect(() => {
        if (chartContainerRef.current) {
            if (!chartRef.current) {
                chartRef.current = createChart(chartContainerRef.current, {
                    width: chartContainerRef.current.clientWidth,
                    height: 400,
                    timeScale: {
                        timeVisible: true,
                        secondsVisible: true,
                        tickMarkFormatter: (time, tickMarkType, locale) => {
                            const date = new Date(time * 1000);
                            const hours = date.getHours().toString().padStart(2, '0');
                            const minutes = date.getMinutes().toString().padStart(2, '0');
                            return `${hours}:${minutes}`;
                        }
                    },
                    priceScale: {
                        scaleMargins: {
                            top: 0.3,
                            bottom: 0.25,
                        },
                        borderVisible: false,
                        mode: 1, // Use 'Right' price scale
                    },
                    grid: {
                        vertLines: {
                            color: 'rgba(197, 203, 206, 0.5)',
                        },
                        horzLines: {
                            color: 'rgba(197, 203, 206, 0.5)',
                        },
                    },
                    localization: {
                        priceFormatter: (price) => {
                            return price > 0 ? `+${price}` : `${price}`;
                        },
                    },
                });
                lineSeriesRef.current = chartRef.current.addLineSeries({
                    priceLineVisible: true,
                    baseLineVisible: true,
                    baseLinePrice: 100,  // Center the y-axis at 100
                });

                chartRef.current.priceScale('right').applyOptions({
                    autoScale: false,
                    minValue: -100,
                    maxValue: 300,
                    borderVisible: false,
                });
            }

            lineSeriesRef.current.setData(
                data.map(item => ({
                    time: new Date(item.timestamp).getTime() / 1000,  // Convert to seconds
                    value: item.odds,
                }))
            );
        }

        // Cleanup function to destroy the chart on component unmount
        return () => {
            if (chartRef.current) {
                chartRef.current.remove();
                chartRef.current = null;
                lineSeriesRef.current = null;
            }
        };
    }, [data]);

    return (
        <div>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', position: 'relative', zIndex: 10 }}>
                <Select
                    options={bookmakers}
                    value={selectedBookmaker}
                    onChange={setSelectedBookmaker}
                    placeholder="Select Bookmaker"
                    styles={{
                        container: (provided) => ({ ...provided, zIndex: 20 }),
                        menu: (provided) => ({ ...provided, zIndex: 20 }),
                    }}
                />
                <Select
                    options={outcomeNames}
                    value={selectedOutcomeName}
                    onChange={setSelectedOutcomeName}
                    placeholder="Select Outcome Name"
                    styles={{
                        container: (provided) => ({ ...provided, zIndex: 20 }),
                        menu: (provided) => ({ ...provided, zIndex: 20 }),
                    }}
                />
                <Select
                    options={outcomeDescriptions}
                    value={selectedOutcomeDescription}
                    onChange={setSelectedOutcomeDescription}
                    placeholder="Select Outcome Description"
                    styles={{
                        container: (provided) => ({ ...provided, zIndex: 20 }),
                        menu: (provided) => ({ ...provided, zIndex: 20 }),
                    }}
                />
            </div>
            <div ref={chartContainerRef} style={{ position: 'relative', zIndex: 1 }} />
        </div>
    );
};

export default OddsHistoryChart;
