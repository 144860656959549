import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaUserCircle } from 'react-icons/fa';
import SportOdds from './SportOdds';
import PlayerProps from './PlayerProps';
import Signup from './Signup';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import Unauthorized from './Unauthorized';
import Locks from './Locks';
import PricingPage from './PricingPage';
import LandingPage from './LandingPage';
import TrackedBets from './TrackedBets';
import OddsHistoryChart from './components/OddsHistoryChart';
import Profile from './Profile';
import Settings from './Settings';
import ForgotPassword from './ForgotPassword';

import Sidebar from './components/Sidebar';
import PositiveEV from './PositiveEV';

const App = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('accessLevel');
    window.location.href = '/login';
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const Header = () => (
    <StyledHeader>
      <HeaderContent>
        <Logo to="/">BetPulse</Logo>
        <Nav>
          <Dropdown>
            <DropdownToggle>
              Sports
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem to="/mlb">MLB</DropdownItem>
              <DropdownItem to="/nba">NBA</DropdownItem>
              <DropdownItem to="/nhl">NHL</DropdownItem>
              <DropdownItem to="/tennis">Tennis</DropdownItem>
              <DropdownItem to="/ufc">UFC</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown>
            <DropdownToggle>
              Player Props
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem to="/mlb/player-props">MLB Player Props</DropdownItem>
              <DropdownItem to="/nba/player-props">NBA Player Props</DropdownItem>
              <DropdownItem to="/nhl/player-props">NHL Player Props</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <NavLink to="/locks">Locks</NavLink>
          <NavLink to="/tracked-bets">Tracked Bets</NavLink>
          <NavLink to="/top-ev-bets">Top EV Bets</NavLink>
        </Nav>
        <NavRight>
          <NavLink to="/pricing">Pricing</NavLink>
          <NavLink to="/signup">Signup</NavLink>
          <NavLink to="/login">Login</NavLink>
          <LogoutLink onClick={handleLogout}>Logout</LogoutLink>
          <JoinDiscordButton href="https://discord.com">Join Discord</JoinDiscordButton>
          <ProfileIcon onClick={toggleProfileDropdown}>
            <FaUserCircle size={28} />
            {isProfileDropdownOpen && (
              <DropdownMenu>
                <DropdownItem to="/profile">View Profile</DropdownItem>
                <DropdownItem to="/settings">Settings</DropdownItem>
              </DropdownMenu>
            )}
          </ProfileIcon>
        </NavRight>
      </HeaderContent>
      <ToggleSidebarButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
        {isSidebarOpen ? '>' : '<'}
      </ToggleSidebarButton>
    </StyledHeader>
  );

  return (
    <Router>
      <Container>
        <Header />
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/mlb" element={<PrivateRoute element={() => <SportOdds leagueKeys={['baseball_mlb']} title="MLB Game Odds" />} requiredAccessLevel="paid, admin" />} />
            <Route path="/nba" element={<PrivateRoute element={() => <SportOdds leagueKeys={['basketball_nba']} title="NBA Game Odds" />} requiredAccessLevel="paid, admin" />} />
            <Route path="/nhl" element={<PrivateRoute element={() => <SportOdds leagueKeys={['icehockey_nhl']} title="NHL Game Odds" />} requiredAccessLevel="paid, admin" />} />
            <Route path="/tennis" element={<PrivateRoute element={() => <SportOdds leagueKeys={['tennis_atp_french_open']} title="Tennis ATP French Open Match Odds" />} requiredAccessLevel="paid, admin" />} />
            <Route path="/ufc" element={<PrivateRoute element={() => <SportOdds leagueKeys={['mma_mixed_martial_arts']} title="UFC Match Odds" />} requiredAccessLevel="paid, admin" />} />
            <Route path="/mlb/player-props" element={<PrivateRoute element={() => <PlayerProps sportKey="baseball_mlb" title="MLB Player Props" />} requiredAccessLevel="paid, admin" />} />
            <Route path="/nba/player-props" element={<PrivateRoute element={() => <PlayerProps sportKey="basketball_nba" title="NBA Player Props" />} requiredAccessLevel="paid, admin" />} />
            <Route path="/nhl/player-props" element={<PrivateRoute element={() => <PlayerProps sportKey="icehockey_nhl" title="NHL Player Props" />} requiredAccessLevel="paid, admin" />} />
            <Route path="/odds-history/:eventId/:marketKey/:bookmakerKey?/:outcomeName?/:outcomeDescription?" element={<OddsHistoryChart />} />
<Route path="/props-history/:eventId/:marketKey/:bookmakerKey?/:outcomeName?/:outcomeDescription?" element={<OddsHistoryChart />} />

            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/locks" element={<PrivateRoute element={() => <Locks />} requiredAccessLevel="paid, admin" />} />
            <Route path="/tracked-bets" element={<PrivateRoute element={() => <TrackedBets />} requiredAccessLevel="paid, admin" />} />
            <Route path="/top-ev-bets" element={<PrivateRoute element={() => <PositiveEV leagueKeys={['baseball_mlb', 'icehockey_nhl', 'basketball_nba', 'tennis_atp_french_open']} />} requiredAccessLevel="admin" />} />
            <Route path="/profile" element={<PrivateRoute element={() => <Profile />} requiredAccessLevel="paid, admin" />} />
            <Route path="/settings" element={<PrivateRoute element={() => <Settings />} requiredAccessLevel="paid, admin" />} />
            <Route path="*" element={<Navigate to={localStorage.getItem('token') ? "/mlb" : "/login"} />} />
          </Routes>
        </MainContent>
        <Footer>
          <FooterText>&copy; 2021 BetPulse</FooterText>
          <FooterLinks>
            <FooterLink href="https://www.betpulse.io">Home</FooterLink>
            <FooterLink href="https://www.betpulse.io">About</FooterLink>
            <FooterLink href="https://www.betpulse.io">Contact</FooterLink>
            <FooterLink href="https://www.betpulse.io">Terms of Service</FooterLink>
            <FooterLink href="https://www.betpulse.io">Privacy Policy</FooterLink>
          </FooterLinks>
        </Footer>
      </Container>
    </Router>
  );
};

export default App;

const Container = styled.div`
  font-family: Arial, sans-serif;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background: #121212;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Logo = styled(Link)`
  font-size: 28px;
  font-weight: bold;
  color: white;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
  flex-grow: 1;
  margin-left: 40px;

  @media (max-width: 768px) {
    gap: 10px;
    margin-left: 20px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    margin-left: 0;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const NavRight = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 768px) {
    gap: 10px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LogoutLink = styled.span`
  color: white;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const JoinDiscordButton = styled.a`
  padding: 10px 20px;
  background-color: #5865f2;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background-color: #4752c4;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 12px;
  }
`;

const ProfileIcon = styled.div`
  position: relative;
  cursor: pointer;

  @media (max-width: 480px) {
    margin-top: 10px;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 17px;
  right: 0;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;

  ${ProfileIcon}:hover & {
    display: block;
  }

  @media (max-width: 768px) {
    top: 25px;
  }

  @media (max-width: 480px) {
    top: 20px;
  }
`;

const DropdownItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  font-size: 14px;

  &:hover {
    background-color: #f0f0f0;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 12px;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 10px;
  }
`;

const Dropdown = styled.div`
  position: relative;

  &:hover ${DropdownMenu} {
    display: block;
  }
`;

const DropdownToggle = styled.div`
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const MainContent = styled.main`
  padding: 0;
  background-color: #1F2836;
  min-height: calc(100vh - 60px);
`;

const Footer = styled.footer`
  padding: 20px;
  background-color: #0d0d0d;
  color: white;
  text-align: center;
  height: 100%;
`;

const FooterText = styled.p`
  margin: 0;
`;

const FooterLinks = styled.div`
  margin-top: 10px;
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 0 10px;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ToggleSidebarButton = styled.button`
  position: fixed;
  top: 50%;
  right: ${props => (props.isOpen ? '250px' : '0')};
  transform: translateY(-50%);
  background: #5865f2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: right 0.3s ease, transform 0.3s ease;
  z-index: 1000;

  &:hover {
    background: #4752c4;
  }
`;
