import axios from 'axios';

const trackBetService = {
    handleTrackBet: async (bet, sportKey) => {
        const userId = localStorage.getItem('user_id');

        if (!userId) {
            console.error('User ID is null. Please ensure the user is logged in.');
            return;
        }

        try {
            // Directly set draftkings_taken_odds to draftkingsLine
            const draftkingsOdds = bet.draftkingsLine;

            console.log("Sending tracked bet data:", {
                user_id: userId,
                sport_key: sportKey,
                event_id: bet.event.id,
                market_key: bet.market,
                outcome_name: bet.outcome,
                draftkings_taken_odds: draftkingsOdds,
                outcomeDescription: bet.outcomeDescription,
                point: bet.point,
                bovadaLine: bet.bovadaLine,
                draftkingsLine: bet.draftkingsLine,
                pinnacleLine: bet.pinnacleLine,
                bovadaImpliedProbability: bet.bovadaImpliedProbability,
                draftkingsImpliedProbability: bet.draftkingsImpliedProbability,
                pinnacleImpliedProbability: bet.pinnacleImpliedProbability
            });

            const trackedBetData = {
                user_id: userId,
                sport_key: sportKey,
                event_id: bet.event.id,
                market_key: bet.market,
                outcome_name: bet.outcome,
                draftkings_taken_odds: draftkingsOdds,
                outcomeDescription: bet.outcomeDescription,
                point: bet.point,
                bovadaLine: bet.bovadaLine,
                draftkingsLine: bet.draftkingsLine,
                pinnacleLine: bet.pinnacleLine,
                bovadaImpliedProbability: bet.bovadaImpliedProbability,
                draftkingsImpliedProbability: bet.draftkingsImpliedProbability,
                pinnacleImpliedProbability: bet.pinnacleImpliedProbability
            };

            const postResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/tracked-bets`, trackedBetData);
            console.log('Tracked Bet:', postResponse.data);
        } catch (error) {
            console.error('Error tracking bet:', error);
        }
    }
};

export default trackBetService;
