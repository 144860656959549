import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './ag-grid-theme-builder.css'; // Import custom dark theme CSS

import trackBetService from './services/trackBetService';

const Locks = () => {
    const [locks, setLocks] = useState([]);
    const [previousLocks, setPreviousLocks] = useState([]);
    const [isSoundEnabled, setIsSoundEnabled] = useState(false);

    const [columnDefs] = useState([
        { headerName: 'Event', field: 'event', valueGetter: (params) => `${params.data.event.home_team} vs ${params.data.event.away_team}` },
        { headerName: 'Market', field: 'market' },
        { headerName: 'Outcome', field: 'outcome' },
        { headerName: 'Outcome Description', field: 'outcomeDescription' },
        { headerName: 'Point', field: 'point' },
        { headerName: 'Bovada Line', field: 'bovadaLine', valueGetter: (params) => params.data.bovadaLine !== undefined ? params.data.bovadaLine : 'N/A' },
        { headerName: 'DraftKings Line', field: 'draftkingsLine' },
        { headerName: 'Pinnacle Line', field: 'pinnacleLine', valueGetter: (params) => params.data.pinnacleLine !== undefined ? params.data.pinnacleLine : 'N/A' },
        { headerName: 'Bovada Implied Probability', field: 'bovadaImpliedProbability', valueGetter: (params) => params.data.bovadaImpliedProbability !== undefined ? `${params.data.bovadaImpliedProbability}%` : 'N/A' },
        { headerName: 'DraftKings Implied Probability', field: 'draftkingsImpliedProbability', valueGetter: (params) => `${params.data.draftkingsImpliedProbability}%` },
        { headerName: 'Pinnacle Implied Probability', field: 'pinnacleImpliedProbability', valueGetter: (params) => params.data.pinnacleImpliedProbability !== undefined ? `${params.data.pinnacleImpliedProbability}%` : 'N/A' },
        { headerName: 'Bovada Last Update', field: 'bovadaLastUpdate', valueGetter: (params) => formatLastUpdate(params.data.bovadaLastUpdate) },
        { headerName: 'DraftKings Last Update', field: 'draftkingsLastUpdate', valueGetter: (params) => formatLastUpdate(params.data.draftkingsLastUpdate) },
        { headerName: 'Pinnacle Last Update', field: 'pinnacleLastUpdate', valueGetter: (params) => formatLastUpdate(params.data.pinnacleLastUpdate) },
        { headerName: 'Action', field: 'action', cellRendererFramework: (params) => (
            <StyledButton onClick={() => handleTrackBet(params.data)}>Track Bet</StyledButton>
        ) },
    ]);

    useEffect(() => {
        const fetchLocks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/locks`);
                const newLocks = response.data || [];

                if (newLocks.length > 0 && newLocks.length !== previousLocks.length) {
                    if (isSoundEnabled) {
                        playAudio('/sounds/bits.ogg');
                    }
                }

                setLocks(newLocks);
                setPreviousLocks(newLocks);
            } catch (error) {
                console.error('Error fetching locks:', error);
            }
        };

        fetchLocks();
        const intervalId = setInterval(fetchLocks, 5000);

        return () => clearInterval(intervalId);
    }, [previousLocks, isSoundEnabled]);

    const enableSound = () => {
        setIsSoundEnabled(true);
        playAudio('/sounds/bits.ogg');
    };

    const playTestSound = () => {
        playAudio('/sounds/bits.ogg');
    };

    const playAudio = (path) => {
        const audio = new Audio(path);
        audio.play().catch(error => {
            console.error('Error playing audio:', error);
        });
    };

    const formatLastUpdate = (lastUpdate) => {
        if (!lastUpdate) return 'N/A';
        const date = new Date(lastUpdate);
        return date.toLocaleString();
    };

    const handleTrackBet = async (lock) => {
        const trackedBet = {
            event: lock.event,
            market: lock.market,
            outcome: lock.outcome,
            outcomeDescription: lock.outcomeDescription,
            point: lock.point,
            bovadaLine: lock.bovadaLine,
            draftkingsLine: lock.draftkingsLine,
            pinnacleLine: lock.pinnacleLine,
            bovadaImpliedProbability: lock.bovadaImpliedProbability,
            draftkingsImpliedProbability: lock.draftkingsImpliedProbability,
            pinnacleImpliedProbability: lock.pinnacleImpliedProbability,
        };

        const sportKey = lock.sportKey; // Ensure sportKey is included
        console.log('Tracking Bet:', trackedBet, 'Sport Key:', sportKey);

        try {
            await trackBetService.handleTrackBet(trackedBet, sportKey);
            console.log('Bet tracked successfully');
        } catch (error) {
            console.error('Error tracking bet:', error);
        }
    };

    return (
        <Container>
            <Title>Locks</Title>
            {!isSoundEnabled && <EnableSoundButton onClick={enableSound}>Enable Sound</EnableSoundButton>}
            <TestSoundButton onClick={playTestSound}>Test Sound</TestSoundButton>
            <div className="ag-theme-custom" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={locks}
                    columnDefs={columnDefs}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
        </Container>
    );
};

export default Locks;

const Container = styled.div`
    padding: 20px;
    background-color: #f8f9fa;
    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Title = styled.h2`
    margin: 0;
    font-size: 24px;
    color: #343a40;
    @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
    }
`;

const EnableSoundButton = styled.button`
    padding: 10px 20px;
    margin: 10px 10px 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #0056b3;
    }
    @media (max-width: 768px) {
        padding: 8px 16px;
        margin: 5px;
    }
`;

const TestSoundButton = styled.button`
    padding: 10px 20px;
    margin: 10px 0;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #218838;
    }
    @media (max-width: 768px) {
        padding: 8px 16px;
        margin: 5px;
    }
`;

const StyledButton = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    &:hover {
        background-color: #0056b3;
        transform: scale(1.05);
    }
    &:active {
        background-color: #004085;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
    }
    &:disabled {
        background-color: #6c757d;
        cursor: not-allowed;
    }
    @media (max-width: 768px) {
        padding: 4px 8px;
        font-size: 14px;
    }
    @media (max-width: 480px) {
        padding: 3px 6px;
        font-size: 12px;
    }
`;
