import React from 'react';
import styled from 'styled-components';

// Function to get the logo path based on the team name
const getLogoPath = (teamName) => {
    const formattedName = teamName.toLowerCase().replace(/ /g, '_') + '_logo.png';
    return `/logos/team_logos/${formattedName}`; // Update this path according to your structure
};

const TeamCellRenderer = ({ value }) => {
    const { home_team, away_team } = value;
    // console.log("TeamCellRenderer value:", value); // Simple log
    // console.log("TeamCellRenderer value:", JSON.stringify(value, null, 2)); // Detailed log

    return (
        <Container>
            <TeamRow>
                <TeamLogo src={getLogoPath(home_team)} alt={`${home_team} logo`} />
                <TeamName>{home_team}</TeamName>
            </TeamRow>
            <TeamRow>
                <TeamLogo src={getLogoPath(away_team)} alt={`${away_team} logo`} />
                <TeamName>{away_team}</TeamName>
            </TeamRow>
        </Container>
    );
};

export default TeamCellRenderer;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the container takes full height */
    justify-content: center; /* Center children vertically */
`;

const TeamRow = styled.div`
    display: flex;
    align-items: center; /* Center items vertically */
`;

const TeamLogo = styled.img`
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
    margin-right: 10px; /* Space between logo and team name */
`;

const TeamName = styled.div`
    flex: 1;
    text-align: left;
`;
