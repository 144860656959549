import React from 'react';
import styled from 'styled-components';

const EVEventCellRenderer = ({ value }) => {
    if (!value) {
        return null;
    }

    const eventDate = new Date(value);

    const formattedDate = eventDate.toLocaleDateString(undefined, {
        month: 'numeric',
        day: 'numeric',
    });
    
    const formattedTime = eventDate.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    return (
        <Container>
            <EventTime>{formattedDate}</EventTime>
            <EventTime>{formattedTime}</EventTime>
        </Container>
    );
};

export default EVEventCellRenderer;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const EventTime = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;