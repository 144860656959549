
// Friendly names for markets
const marketFriendlyNames = {
    americanfootball_nfl: {
        gameOdds: {
            h2h: 'Head-to-Head',
            spreads: 'Spreads',
            totals: 'Totals',
            outrights: 'Outrights',
            h2h_q1: 'Head-to-Head Q1',
            h2h_q2: 'Head-to-Head Q2',
            h2h_q3: 'Head-to-Head Q3',
            h2h_q4: 'Head-to-Head Q4',
            spreads_q1: 'Spreads Q1',
            spreads_q2: 'Spreads Q2',
            spreads_q3: 'Spreads Q3',
            spreads_q4: 'Spreads Q4',
            totals_q1: 'Totals Q1',
            totals_q2: 'Totals Q2',
            totals_q3: 'Totals Q3',
            totals_q4: 'Totals Q4'
        },
        playerProps: {
            player_pass_tds: 'Player Pass TDs',
            player_pass_yds: 'Player Pass Yards',
            player_pass_completions: 'Player Pass Completions',
            player_pass_attempts: 'Player Pass Attempts',
            player_pass_interceptions: 'Player Pass Interceptions',
            player_pass_longest_completion: 'Player Longest Pass Completion',
            player_rush_yds: 'Player Rush Yards',
            player_rush_attempts: 'Player Rush Attempts',
            player_rush_longest: 'Player Longest Rush',
            player_receptions: 'Player Receptions',
            player_reception_yds: 'Player Reception Yards',
            player_reception_longest: 'Player Longest Reception',
            player_kicking_points: 'Player Kicking Points',
            player_field_goals: 'Player Field Goals',
            player_tackles_assists: 'Player Tackles & Assists',
            player_1st_td: 'Player 1st TD',
            player_last_td: 'Player Last TD',
            player_anytime_td: 'Player Anytime TD'
        }
    },
    basketball_nba: {
        gameOdds: {
            h2h: 'Head-to-Head',
            spreads: 'Spreads',
            totals: 'Totals',
            alternate_spreads: 'Alternate Spreads',
            alternate_totals: 'Alternate Totals',
            team_totals: 'Team Totals',
            alternate_team_totals: 'Alternate Team Totals',
            h2h_q1: 'Head-to-Head Q1',
            h2h_q2: 'Head-to-Head Q2',
            h2h_q3: 'Head-to-Head Q3',
            h2h_q4: 'Head-to-Head Q4',
            spreads_q1: 'Spreads Q1',
            spreads_q2: 'Spreads Q2',
            spreads_q3: 'Spreads Q3',
            spreads_q4: 'Spreads Q4',
            h2h_h1: 'Head-to-Head H1',
            h2h_h2: 'Head-to-Head H2',
            totals_q1: 'Totals Q1',
            totals_q2: 'Totals Q2',
            totals_q3: 'Totals Q3',
            totals_q4: 'Totals Q4',
            totals_h1: 'Totals H1',
            totals_h2: 'Totals H2',
            alternate_spreads_q1: 'Alternate Spreads Q1',
            alternate_spreads_h1: 'Alternate Spreads H1',
            alternate_totals_q1: 'Alternate Totals Q1',
            alternate_totals_h1: 'Alternate Totals H1'
        },
        playerProps: {
            player_points: 'Player Points',
            player_rebounds: 'Player Rebounds',
            player_assists: 'Player Assists',
            player_threes: 'Player Threes',
            player_blocks: 'Player Blocks',
            player_steals: 'Player Steals',
            player_blocks_steals: 'Player Blocks & Steals',
            player_turnovers: 'Player Turnovers',
            player_points_rebounds_assists: 'Player Points, Rebounds & Assists',
            player_points_rebounds: 'Player Points & Rebounds',
            player_points_assists: 'Player Points & Assists',
            player_rebounds_assists: 'Player Rebounds & Assists',
            player_first_basket: 'Player First Basket',
            player_double_double: 'Player Double-Double',
            player_triple_double: 'Player Triple-Double'
        }
    },
    baseball_mlb: {
        gameOdds: {
            h2h: 'Head-to-Head',
            spreads: 'Spreads',
            totals: 'Totals',
            alternate_spreads: 'Alternate Spreads',
            alternate_totals: 'Alternate Totals',
            team_totals: 'Team Totals',
            alternate_team_totals: 'Alternate Team Totals',
            h2h_1st_1_innings: 'Head-to-Head 1st Inning',
            h2h_1st_3_innings: 'Head-to-Head 1st 3 Innings',
            h2h_1st_5_innings: 'Head-to-Head 1st 5 Innings',
            h2h_1st_7_innings: 'Head-to-Head 1st 7 Innings',
            spreads_1st_1_innings: 'Spreads 1st Inning',
            spreads_1st_3_innings: 'Spreads 1st 3 Innings',
            spreads_1st_5_innings: 'Spreads 1st 5 Innings',
            spreads_1st_7_innings: 'Spreads 1st 7 Innings',
            alternate_spreads_1st_1_innings: 'Alternate Spreads 1st Inning',
            alternate_spreads_1st_3_innings: 'Alternate Spreads 1st 3 Innings',
            alternate_spreads_1st_5_innings: 'Alternate Spreads 1st 5 Innings',
            alternate_spreads_1st_7_innings: 'Alternate Spreads 1st 7 Innings',
            totals_1st_1_innings: 'Totals 1st Inning',
            totals_1st_3_innings: 'Totals 1st 3 Innings',
            totals_1st_5_innings: 'Totals 1st 5 Innings',
            totals_1st_7_innings: 'Totals 1st 7 Innings',
            alternate_totals_1st_1_innings: 'Alternate Totals 1st Inning',
            alternate_totals_1st_3_innings: 'Alternate Totals 1st 3 Innings',
            alternate_totals_1st_5_innings: 'Alternate Totals 1st 5 Innings',
            alternate_totals_1st_7_innings: 'Alternate Totals 1st 7 Innings'
        },
        playerProps: {
            batter_home_runs: 'Batter Home Runs',
            batter_hits: 'Batter Hits',
            batter_total_bases: 'Batter Total Bases',
            batter_rbis: 'Batter RBIs',
            batter_runs_scored: 'Batter Runs Scored',
            batter_hits_runs_rbis: 'Batter Hits, Runs & RBIs',
            batter_singles: 'Batter Singles',
            batter_doubles: 'Batter Doubles',
            batter_triples: 'Batter Triples',
            batter_walks: 'Batter Walks',
            batter_strikeouts: 'Batter Strikeouts',
            batter_stolen_bases: 'Batter Stolen Bases',
            pitcher_strikeouts: 'Pitcher Strikeouts',
            pitcher_strikeouts_alternate: 'Pitcher Strikeouts (Alternate)',
            pitcher_record_a_win: 'Pitcher Record a Win',
            pitcher_hits_allowed: 'Pitcher Hits Allowed',
            pitcher_walks: 'Pitcher Walks',
            pitcher_earned_runs: 'Pitcher Earned Runs',
            pitcher_outs: 'Pitcher Outs'
        }
    },
    soccer_uefa_european_championship: {
        gameOdds: {
            h2h: 'Head-to-Head',
            totals: 'Totals',
            spreads: 'Spreads',
            btts: 'Both Teams to Score',
            draw_no_bet: 'Draw No Bet',
            h2h_3_way: 'Head-to-Head 3-Way',
            team_totals: 'Team Totals',
            alternate_totals: 'Alternate Totals',
            alternate_spreads: 'Alternate Spreads'
        },
        playerProps: {}
    },
    icehockey_nhl: {
        gameOdds: {
            h2h: 'Head-to-Head',
            spreads: 'Spreads',
            totals: 'Totals',
            h2h_p1: 'Head-to-Head Period 1',
            h2h_p2: 'Head-to-Head Period 2',
            h2h_p3: 'Head-to-Head Period 3',
            spreads_p1: 'Spreads Period 1',
            spreads_p2: 'Spreads Period 2',
            spreads_p3: 'Spreads Period 3',
            totals_p1: 'Totals Period 1',
            totals_p2: 'Totals Period 2',
            totals_p3: 'Totals Period 3'
        },
        playerProps: {
            player_points: 'Player Points',
            player_power_play_points: 'Player Power Play Points',
            player_assists: 'Player Assists',
            player_blocked_shots: 'Player Blocked Shots',
            player_shots_on_goal: 'Player Shots on Goal',
            player_goals: 'Player Goals',
            player_total_saves: 'Player Total Saves',
            player_goal_scorer_first: 'Player First Goal Scorer',
            player_goal_scorer_last: 'Player Last Goal Scorer',
            player_goal_scorer_anytime: 'Player Anytime Goal Scorer'
        }

    },
    tennis_atp_french_open: {
        gameOdds: {
            h2h: 'Head-to-Head',
            totals: 'Totals',
            spreads: 'Spreads',
            h2h_1st_set: 'Head-to-Head 1st Set',
            h2h_2nd_set: 'Head-to-Head 2nd Set',
            h2h_3rd_set: 'Head-to-Head 3rd Set',
            spreads_1st_set: 'Spreads 1st Set',
            spreads_2nd_set: 'Spreads 2nd Set',
            spreads_3rd_set: 'Spreads 3rd Set',
            totals_1st_set: 'Totals 1st Set',
            totals_2nd_set: 'Totals 2nd Set',
            totals_3rd_set: 'Totals 3rd Set'
        },
        playerProps: {
            player_aces: 'Player Aces',
            player_double_faults: 'Player Double Faults',
            player_first_set_winner: 'Player First Set Winner',
            player_match_winner: 'Player Match Winner',
            player_total_games: 'Player Total Games',
            player_total_points: 'Player Total Points',
            player_total_sets: 'Player Total Sets',
            player_total_tiebreaks: 'Player Total Tiebreaks'
        }
    },

};

export default marketFriendlyNames;
