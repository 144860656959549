import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FaCheckCircle, FaEdit, FaSave, FaUserCircle } from 'react-icons/fa';

const Profile = () => {
    const [profile, setProfile] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
        country: ''
    });
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState({
        first_name: false,
        last_name: false,
        email: false,
        phone_number: false,
        date_of_birth: false,
        country: false
    });

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setProfile(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error.response ? error.response.data : error.message);
                setError('Error fetching profile. Please try again.');
            }
        };

        fetchProfile();
    }, []);

    const handleProfileChange = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    };

    const handleFieldSubmit = async (field) => {
        setMessage('');
        setError('');
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/profile`, profile, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setMessage('Profile updated successfully.');
            setProfile(response.data);
            setIsEditing({ ...isEditing, [field]: false });
        } catch (error) {
            setError('Error updating profile. Please try again.');
            console.error('Error updating profile:', error.response ? error.response.data : error.message);
        }
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/profile/password`, { password }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setMessage('Password updated successfully.');
            setPassword('');
        } catch (error) {
            setError('Error updating password. Please try again.');
            console.error('Error updating password:', error.response ? error.response.data : error.message);
        }
    };

    const toggleEdit = (field) => {
        setIsEditing({ ...isEditing, [field]: !isEditing[field] });
    };

    return (
        <Container>
            <ProfileWrapper>
                <ProfileHeader>
                    <ProfileImage>
                        <FaUserCircle size={100} />
                    </ProfileImage>
                    <HeaderInfo>
                        <h1>Account Information</h1>
                    </HeaderInfo>
                </ProfileHeader>
                <ProfileContent>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <FormField>
                            <Label>First Name:</Label>
                            <Input
                                type="text"
                                name="first_name"
                                value={profile.first_name}
                                onChange={handleProfileChange}
                                disabled={!isEditing.first_name}
                            />
                            <EditButton onClick={() => toggleEdit('first_name')}>
                                {isEditing.first_name ? <FaSave onClick={() => handleFieldSubmit('first_name')} /> : <FaEdit />}
                            </EditButton>
                        </FormField>
                        <FormField>
                            <Label>Last Name:</Label>
                            <Input
                                type="text"
                                name="last_name"
                                value={profile.last_name}
                                onChange={handleProfileChange}
                                disabled={!isEditing.last_name}
                            />
                            <EditButton onClick={() => toggleEdit('last_name')}>
                                {isEditing.last_name ? <FaSave onClick={() => handleFieldSubmit('last_name')} /> : <FaEdit />}
                            </EditButton>
                        </FormField>
                        <FormField>
                            <Label>Email:</Label>
                            <Input
                                type="email"
                                name="email"
                                value={profile.email}
                                onChange={handleProfileChange}
                                disabled={!isEditing.email}
                            />
                            <VerifiedBadge>
                                <FaCheckCircle /> Verified
                            </VerifiedBadge>
                            <EditButton onClick={() => toggleEdit('email')}>
                                {isEditing.email ? <FaSave onClick={() => handleFieldSubmit('email')} /> : <FaEdit />}
                            </EditButton>
                        </FormField>
                        <FormField>
                            <Label>Phone Number:</Label>
                            <Input
                                type="text"
                                name="phone_number"
                                value={profile.phone_number}
                                onChange={handleProfileChange}
                                disabled={!isEditing.phone_number}
                            />
                            <VerifiedBadge>
                                <FaCheckCircle /> Verified
                            </VerifiedBadge>
                            <EditButton onClick={() => toggleEdit('phone_number')}>
                                {isEditing.phone_number ? <FaSave onClick={() => handleFieldSubmit('phone_number')} /> : <FaEdit />}
                            </EditButton>
                        </FormField>
                        <FormField>
                            <Label>Date of Birth:</Label>
                            <Input
                                type="date"
                                name="date_of_birth"
                                value={profile.date_of_birth}
                                onChange={handleProfileChange}
                                disabled={!isEditing.date_of_birth}
                            />
                            <EditButton onClick={() => toggleEdit('date_of_birth')}>
                                {isEditing.date_of_birth ? <FaSave onClick={() => handleFieldSubmit('date_of_birth')} /> : <FaEdit />}
                            </EditButton>
                        </FormField>
                        <FormField>
                            <Label>Country:</Label>
                            <Input
                                type="text"
                                name="country"
                                value={profile.country}
                                onChange={handleProfileChange}
                                disabled={!isEditing.country}
                            />
                            <EditButton onClick={() => toggleEdit('country')}>
                                {isEditing.country ? <FaSave onClick={() => handleFieldSubmit('country')} /> : <FaEdit />}
                            </EditButton>
                        </FormField>
                    </Form>
                    <Form onSubmit={handlePasswordChange}>
                        <FormField>
                            <Label>New Password:</Label>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </FormField>
                        <Button type="submit">Update Password</Button>
                    </Form>
                    {message && <Message>{message}</Message>}
                    {error && <Error>{error}</Error>}
                </ProfileContent>
            </ProfileWrapper>
        </Container>
    );
};

export default Profile;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
`;

const ProfileWrapper = styled.div`
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
`;

const ProfileHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const ProfileImage = styled.div`
    margin-right: 20px;
`;

const HeaderInfo = styled.div`
    h1 {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
    }
`;

const ProfileContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormField = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
`;

const Label = styled.label`
    width: 150px;
    font-weight: bold;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex-grow: 1;
    font-size: 14px;
    &:disabled {
        background-color: #f9f9f9;
    }
`;

const Button = styled.button`
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background: #0056b3;
    }
`;

const EditButton = styled.button`
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #0056b3;
    }
`;

const VerifiedBadge = styled.span`
    display: flex;
    align-items: center;
    color: green;
    font-size: 14px;

    svg {
        margin-right: 5px;
    }
`;

const Message = styled.p`
    color: green;
    margin-top: 20px;
`;

const Error = styled.p`
    color: red;
    margin-top: 20px;
`;
