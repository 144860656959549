import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './ag-grid-theme-builder.css';
import TeamCellRenderer from './TeamCellRenderer';
import BestLineCellRenderer from './renderers/BestLineCellRenderer';
import PinnacleOddsCellRenderer from './renderers/PinnacleOddsCellRenderer';
import marketFriendlyNames from './marketFriendlyNames';
import EVEventCellRenderer from './EVEventCellRenderer';
import { Link } from 'react-router-dom';

const PositiveEV = ({ leagueKeys = [], title }) => {
    const [odds, setOdds] = useState([]);
    const [marketMaker, setMarketMaker] = useState('pinnacle');
    const [bankroll, setBankroll] = useState(1000);
    const [fractionalKelly, setFractionalKelly] = useState(1);
    const [showArbitrage, setShowArbitrage] = useState(false);

    const convertAmericanToDecimal = (odds) => {
        if (odds > 0) {
            return (odds / 100) + 1;
        } else {
            return (100 / Math.abs(odds)) + 1;
        }
    };

    const calculateEV = (odds, trueProbability) => {
        const decimalOdds = convertAmericanToDecimal(odds);
        const payout = decimalOdds - 1;
        const EV = (trueProbability * payout) - (1 - trueProbability);
        return EV;
    };

    const calculateEdgeAndEV = (bestOdds, bestReciprocalOdds, pnOdds, pnReciprocalOdds) => {
        const pnDecimalOdds = convertAmericanToDecimal(pnOdds);
        const pnReciprocalDecimalOdds = convertAmericanToDecimal(pnReciprocalOdds);

        const pnImpliedProb = 1 / pnDecimalOdds;
        const pnReciprocalImpliedProb = 1 / pnReciprocalDecimalOdds;

        const totalImpliedProbPN = pnImpliedProb + pnReciprocalImpliedProb;

        const pnTrueProb = pnImpliedProb / totalImpliedProbPN;
        const pnReciprocalTrueProb = pnReciprocalImpliedProb / totalImpliedProbPN;

        const EV = calculateEV(bestOdds, pnTrueProb);
        const reciprocalEV = calculateEV(bestReciprocalOdds, pnReciprocalTrueProb);

        return { EV, reciprocalEV, pnTrueProb };
    };

    const findBestMarket = useCallback((bookmakers, marketKey, marketMakerOdds) => {
        const allMarkets = bookmakers.flatMap(b => b.markets.filter(m => m.key === marketKey).map(market => ({ ...market, sportsbook: b.key })));
        if (allMarkets.length === 0) return null;

        const bestMarket = { key: marketKey, outcomes: [] };
        allMarkets.forEach(market => {
            market.outcomes.forEach(outcome => {
                const existingOutcome = bestMarket.outcomes.find(o => o.name === outcome.name && o.point === outcome.point && o.description === outcome.description);
                if (!existingOutcome || convertAmericanToDecimal(outcome.price) > convertAmericanToDecimal(existingOutcome.price)) {
                    bestMarket.outcomes = bestMarket.outcomes.filter(o => o.name !== outcome.name || o.point !== outcome.point || o.description !== outcome.description);
                    bestMarket.outcomes.push({ ...outcome, sportsbook: market.sportsbook });
                }
            });
        });

        marketMakerOdds.markets.forEach(market => {
            if (market.key === marketKey) {
                market.outcomes.forEach(outcome => {
                    const existingOutcome = bestMarket.outcomes.find(o => o.name === outcome.name && o.point === outcome.point && o.description === outcome.description);
                    if (!existingOutcome || convertAmericanToDecimal(outcome.price) > convertAmericanToDecimal(existingOutcome.price)) {
                        bestMarket.outcomes = bestMarket.outcomes.filter(o => o.name !== outcome.name || o.point !== outcome.point || o.description !== outcome.description);
                        bestMarket.outcomes.push({ ...outcome, sportsbook: marketMaker });
                    }
                });
            }
        });

        return bestMarket;
    }, [marketMaker]);

    const findBestReciprocalOutcome = (bookmakers, marketKey, reciprocalOutcome) => {
        if (!reciprocalOutcome) {
            console.log('Reciprocal outcome is null');
            return null;
        }

        const allMarkets = bookmakers.flatMap(b => b.markets.filter(m => m.key === marketKey).map(market => ({ ...market, sportsbook: b.key })));
        const bestReciprocalOutcome = allMarkets.reduce((best, market) => {
            const outcome = market.outcomes.find(o => 
                o.name === reciprocalOutcome.name && 
                o.point === reciprocalOutcome.point &&
                o.description === reciprocalOutcome.description 
            );
            if (outcome && (!best || convertAmericanToDecimal(outcome.price) > convertAmericanToDecimal(best.price))) {
                return { ...outcome, sportsbook: market.sportsbook };
            }
            return best;
        }, null);

        if (!bestReciprocalOutcome) {
            console.log(`Could not find best reciprocal outcome for market ${marketKey}`);
        }

        return bestReciprocalOutcome;
    };

    const getFriendlyMarketName = (sportKey, marketKey) => {
        const sportMarkets = marketFriendlyNames[sportKey];
        if (sportMarkets) {
            for (const marketType in sportMarkets) {
                const marketNames = sportMarkets[marketType];
                if (marketNames[marketKey]) {
                    return marketNames[marketKey];
                }
            }
        }
        return marketKey;
    };

    const calculatePulseRating = (marketWidth) => {
        if (marketWidth <= 2) return 20;
        if (marketWidth <= 4) return 19;
        if (marketWidth <= 6) return 18;
        if (marketWidth <= 8) return 17;
        if (marketWidth <= 10) return 16;
        if (marketWidth <= 12) return 15;
        if (marketWidth <= 14) return 14;
        if (marketWidth <= 16) return 13;
        if (marketWidth <= 18) return 12;
        if (marketWidth <= 20) return 11;
        if (marketWidth <= 25) return 10;
        if (marketWidth <= 30) return 9;
        if (marketWidth <= 35) return 8;
        if (marketWidth <= 40) return 7;
        if (marketWidth <= 45) return 6;
        if (marketWidth <= 50) return 5;
        if (marketWidth <= 55) return 4;
        if (marketWidth <= 60) return 3;
        if (marketWidth <= 65) return 2;
        return 1;
    };

    const calculateKellyWager = (bankroll, fractionalKelly, ev, decimalOdds) => {
        const edge = ev;
        return bankroll * fractionalKelly * edge / (decimalOdds - 1);
    };

    useEffect(() => {
        const fetchOdds = async () => {
            if (!Array.isArray(leagueKeys) || leagueKeys.length === 0) {
                console.error('Error: leagueKeys must be a non-empty array');
                return;
            }

            try {
                const responses = await Promise.all(
                    leagueKeys.map(key => axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/odds/${key}/game`))
                );
                const playerPropsResponses = await Promise.all(
                    leagueKeys.map(key => axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/odds/${key}/player`))
                );

                const mergedOdds = responses.flatMap(response => response.data || []);
                const mergedPlayerProps = playerPropsResponses.flatMap(response => response.data || []);
                const allOdds = [...mergedOdds, ...mergedPlayerProps];

                console.log('Fetched Odds:', allOdds); // Debug: log fetched data

                const now = new Date();
                const filteredOdds = allOdds.filter(odds => {
                    const eventGameOdds = odds.eventGameOdds;
                    const eventPlayerProps = odds.eventPlayerProps;

                    if (!eventGameOdds && !eventPlayerProps) {
                        console.log('Missing eventGameOdds or eventPlayerProps:', odds);
                        return false;
                    }

                    const commenceTime = new Date(eventGameOdds?.commence_time || eventPlayerProps?.commence_time);
                    if (commenceTime <= now) {
                        return false;
                    }

                    const marketMakerOdds = eventGameOdds?.bookmakers?.find(b => b.key === marketMaker) ||
                                            eventPlayerProps?.bookmakers?.find(b => b.key === marketMaker);
                    if (!marketMakerOdds) {
                        return false;
                    }

                    const otherBookmakers = eventGameOdds?.bookmakers?.filter(b => b.key !== marketMaker) ||
                                            eventPlayerProps?.bookmakers?.filter(b => b.key !== marketMaker);
                    return marketMakerOdds.markets.some(pnMarket => {
                        const bestMarket = findBestMarket(otherBookmakers, pnMarket.key, marketMakerOdds);
                        if (!bestMarket) {
                            return false;
                        }

                        return pnMarket.outcomes.some(pnOutcome => {
                            const bestOutcome = bestMarket.outcomes.find(bestOutcome =>
                                bestOutcome.name === pnOutcome.name &&
                                bestOutcome.point === pnOutcome.point &&
                                bestOutcome.description === pnOutcome.description
                            );
                            if (!bestOutcome) {
                                return false;
                            }
                            const bestDecimal = convertAmericanToDecimal(bestOutcome.price);
                            const pnDecimal = convertAmericanToDecimal(pnOutcome.price);
                            const validOutcome = bestDecimal <= pnDecimal;
                            return validOutcome;
                        });
                    });
                });

                console.log('Filtered Odds:', filteredOdds); // Debug: log filtered data
                setOdds(filteredOdds);
            } catch (error) {
                console.error('Error fetching odds:', error);
            }
        };

        fetchOdds();
        const intervalId = setInterval(fetchOdds, 30000);

        return () => clearInterval(intervalId);
    }, [leagueKeys, findBestMarket, marketMaker]);

    const columns = useMemo(() => [
        { headerName: 'EV %', field: 'ev', cellRenderer: 'EVCellRenderer', sort: 'desc' },
        { headerName: 'Px', field: 'true_probability' },
        { headerName: 'Kelly Wager', field: 'kelly_wager' },
        { headerName: 'Pulse Rating', field: 'pulse_rating' },
        { headerName: 'Commence Date', field: 'commence_time', cellRenderer: 'EVEventCellRenderer' },
        { headerName: 'Event Details', field: 'teams', cellRenderer: 'TeamCellRenderer', minWidth: 210 },
        { headerName: 'Market Type', field: 'market_type', minWidth: 250 },
        { headerName: 'Outcome Description', field: 'outcome_description', minWidth: 150 },
        { headerName: 'Point', field: 'point', cellRenderer: 'CellWithReciprocalRenderer' },
        { headerName: 'Bet', field: 'bet', cellRenderer: 'CellWithReciprocalRenderer', minWidth: 200 },
        { headerName: 'Best Line', field: 'best_line', cellRenderer: 'BestLineCellRenderer' },
        { headerName: `${marketMaker.charAt(0).toUpperCase() + marketMaker.slice(1)} Odds`, field: 'market_maker_line', cellRenderer: 'PinnacleOddsCellRenderer', cellRendererParams: { marketMaker } },
        { headerName: 'Market Width', field: 'market_width', cellRenderer: 'CellWithReciprocalRenderer', filter: 'agNumberColumnFilter'},
        { headerName: 'Historical Odds', field: 'historical_odds', cellRenderer: 'HistoricalOddsLinkRenderer' }
    ], [marketMaker]);

    const getReciprocalOutcome = (market, outcome) => {
        const marketKey = market.key;
        if (marketKey.includes('totals') || marketKey.includes('batter') || marketKey.includes('pitcher') || marketKey.includes('player')) {
            const oppositeBet = outcome.name === 'Over' ? 'Under' : 'Over';
            const reciprocalOutcome = market.outcomes.find(o => 
                o.name === oppositeBet && 
                o.point === outcome.point && 
                o.description === outcome.description
            );
            if (!reciprocalOutcome) {
                console.log(`Missing reciprocal for market: ${marketKey}, outcome: ${outcome.name}, point: ${outcome.point}, description: ${outcome.description}`);
            }
            return reciprocalOutcome;
        } else if (marketKey.includes('spreads')) {
            const oppositePoint = -outcome.point;
            const reciprocalOutcome = market.outcomes.find(o => 
                o.name !== outcome.name && 
                o.point === oppositePoint && 
                o.description === outcome.description
            );
            if (!reciprocalOutcome) {
                console.log(`Missing reciprocal for market: ${marketKey}, outcome: ${outcome.name}, point: ${oppositePoint}, description: ${outcome.description}`);
            }
            return reciprocalOutcome;
        } else if (marketKey.includes('h2h')) {
            const reciprocalOutcome = market.outcomes.find(o => 
                o.name !== outcome.name && 
                o.description === outcome.description
            );
            if (!reciprocalOutcome) {
                console.log(`Missing reciprocal for market: ${marketKey}, outcome: ${outcome.name}, description: ${outcome.description}`);
            }
            return reciprocalOutcome;
        }
        return null;
    };
    

    const rowData = odds.flatMap(odds => {
        const marketMakerOdds = odds.eventGameOdds?.bookmakers?.find(b => b.key === marketMaker) ||
                                odds.eventPlayerProps?.bookmakers?.find(b => b.key === marketMaker);
        if (!marketMakerOdds) {
            console.log(`Missing ${marketMaker} odds for event:`, odds);
            return []; // Debug: log if marketMakerOdds is missing
        }
    
        return marketMakerOdds.markets.flatMap(market => {
            const bestMarket = findBestMarket(
                odds.eventGameOdds?.bookmakers?.filter(b => b.key !== marketMaker) ||
                odds.eventPlayerProps?.bookmakers?.filter(b => b.key !== marketMaker), 
                market.key, marketMakerOdds
            );
            if (!bestMarket) {
                console.log('Missing bestMarket for event:', odds);
                return []; // Debug: log if bestMarket is missing
            }
    
            return market.outcomes.flatMap(outcome => {
                const bestOutcome = bestMarket.outcomes.find(bestOutcome =>
                    bestOutcome.name === outcome.name &&
                    bestOutcome.point === outcome.point &&
                    bestOutcome.description === outcome.description
                );
                if (!bestOutcome) {
                    console.log('Missing bestOutcome for event:', odds);
                    return []; // Debug: log if bestOutcome is missing
                }
    
                const bestReciprocalOutcome = findBestReciprocalOutcome(
                    odds.eventGameOdds?.bookmakers ||
                    odds.eventPlayerProps?.bookmakers, 
                    market.key, getReciprocalOutcome(bestMarket, bestOutcome)
                );
                const marketReciprocalOutcome = getReciprocalOutcome(market, outcome);
    
                if (!bestReciprocalOutcome || !marketReciprocalOutcome) {
                    console.log('Missing reciprocal outcomes for event:', odds);
                    return []; // Debug: log if reciprocal outcomes are missing
                }
    
                const marketWidth = Math.abs(Math.abs(outcome.price) - Math.abs(marketReciprocalOutcome.price));
    
                const { EV, reciprocalEV, pnTrueProb } = calculateEdgeAndEV(bestOutcome.price, bestReciprocalOutcome.price, outcome.price, marketReciprocalOutcome.price);
    
                const mainEVDisplay = bestOutcome.sportsbook === marketMaker ? '-' : `${(EV * 100).toFixed(2)}%`;
                const reciprocalEVDisplay = bestReciprocalOutcome.sportsbook === marketMaker ? '-' : `${(reciprocalEV * 100).toFixed(2)}%`;
    
                const kellyWager = calculateKellyWager(bankroll, fractionalKelly, EV, convertAmericanToDecimal(bestOutcome.price));
    
                const row = {
                    ev: `${mainEVDisplay}\n${reciprocalEVDisplay}`,
                    true_probability: (pnTrueProb * 100).toFixed(2),
                    kelly_wager: kellyWager.toFixed(2),
                    commence_time: odds.eventGameOdds?.commence_time || odds.eventPlayerProps?.commence_time,
                    teams: { home_team: odds.eventGameOdds?.home_team || odds.eventPlayerProps?.home_team, away_team: odds.eventGameOdds?.away_team || odds.eventPlayerProps?.away_team },
                    market_type: getFriendlyMarketName(odds.eventGameOdds?.sport_key || odds.eventPlayerProps?.sport_key, market.key),
                    outcome_description: bestOutcome.description,
                    point: `${bestOutcome.point ?? ''}\n${bestReciprocalOutcome ? bestReciprocalOutcome.point ?? '' : ''}`,
                    bet: `${bestOutcome.name}\n${bestReciprocalOutcome ? bestReciprocalOutcome.name : ''}`,
                    best_line: `${bestOutcome.price} (${bestOutcome.sportsbook})\n${bestReciprocalOutcome ? `${bestReciprocalOutcome.price} (${bestReciprocalOutcome.sportsbook})` : ''}`,
                    market_maker_line: `${outcome.price}\n${marketReciprocalOutcome ? marketReciprocalOutcome.price : ''}`,
                    market_width: marketWidth.toFixed(2),
                    pulse_rating: calculatePulseRating(marketWidth),
                    main_bet: bestOutcome.name,
                    implied_prob_sum: 1 / convertAmericanToDecimal(bestOutcome.price) + 1 / convertAmericanToDecimal(bestReciprocalOutcome.price),
                    historical_odds: {
                        eventId: odds.event.id,
                        marketKey: market.key,
                        bookmakerKey: marketMaker,
                        outcomeName: bestOutcome.name,
                        outcomeDescription: bestOutcome.description
                    }
                };
    
                return row;
            });
        });
    }).filter(row => !showArbitrage || row.implied_prob_sum < 1);

    const CellWithReciprocalRenderer = (props) => {
        const [mainBet, ...restBets] = props.value.split('\n');
        return (
            <CellContainer>
                <HighlightedRow>{mainBet}</HighlightedRow>
                {restBets.map((val, idx) => (
                    <Row key={idx}>{val}</Row>
                ))}
            </CellContainer>
        );
    };

    const EVCellRenderer = (props) => {
        const [mainEV, reciprocalEV] = props.value.split('\n');
        return (
            <CellContainer>
                <HighlightedRow>{mainEV}</HighlightedRow>
                <Row>{reciprocalEV}</Row>
            </CellContainer>
        );
    };

    const HistoricalOddsLinkRenderer = (props) => {
        const { eventId, marketKey, bookmakerKey, outcomeName, outcomeDescription } = props.data.historical_odds;
        // if (!eventId) console.log('Missing eventId:', props.data);
        return (
            <Link to={`/odds-history/${eventId}/${marketKey}/${bookmakerKey}/${outcomeName}/${outcomeDescription}`}>
                View Historical Odds
            </Link>
        );
    };

    return (
        <Container>
            <Header>
                <Title>{title}</Title>
                <MarketMakerToggle>
                    <Button onClick={() => setMarketMaker('pinnacle')}>Pinnacle</Button>
                    <Button onClick={() => setMarketMaker('bovada')}>Bovada</Button>
                </MarketMakerToggle>
            </Header>
            <Controls>
                <InputGroup>
                    <Label>Bankroll:</Label>
                    <Input
                        type="number"
                        value={bankroll}
                        onChange={(e) => setBankroll(Number(e.target.value))}
                    />
                </InputGroup>
                <InputGroup>
                    <Label>Fractional Kelly:</Label>
                    <SliderContainer>
                        <Slider
                            type="range"
                            min="0"
                            max="1"
                            step="0.1"
                            value={fractionalKelly}
                            onChange={(e) => setFractionalKelly(Number(e.target.value))}
                        />
                        <SliderValue>{fractionalKelly}</SliderValue>
                    </SliderContainer>
                </InputGroup>
                <Button onClick={() => setShowArbitrage(!showArbitrage)}>
                    {showArbitrage ? 'Show All' : 'Show Arbitrage Opportunities'}
                </Button>
            </Controls>
            <div className="ag-theme-custom" style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columns}
                    rowHeight={100}
                    domLayout='autoHeight'
                    suppressMovableColumns={true}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 50,
                        sortable: true,
                        resizable: true,
                        filter: true,
                    }}
                    components={{
                        EVEventCellRenderer,
                        CellWithReciprocalRenderer,
                        TeamCellRenderer,
                        EVCellRenderer,
                        PinnacleOddsCellRenderer,
                        BestLineCellRenderer,
                        HistoricalOddsLinkRenderer
                    }}
                    context={{ marketMaker }}
                />
            </div>
        </Container>
    );
};

export default PositiveEV;

const Container = styled.div`
    padding: 20px;
    background-color: #1F2836;
    height: 100%;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 2px solid #FFFFFF;
    margin-bottom: 20px;
`;

const Title = styled.h2`
    margin: 0;
    font-size: 24px;
    color: #fff;
`;

const MarketMakerToggle = styled.div`
    display: flex;
    gap: 10px;
`;

const Button = styled.button`
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #555;
    }
`;

const Controls = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
`;

const Label = styled.label`
    color: #fff;
`;

const Input = styled.input`
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100px;
`;

const SliderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Slider = styled.input`
    width: 100px;
`;

const SliderValue = styled.span`
    color: #fff;
`;

const CellContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    margin: 2px 0;
`;

const HighlightedRow = styled(Row)`
    font-weight: bold;
    border-radius: 5px;
`;

