import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/signup`, { username, email, password });
            setSuccess('Signup successful! Redirecting to login...');
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            setError('Signup failed. Please try again.');
            console.error('Error signing up:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <Container>
            <FormWrapper>
                <h2>Signup</h2>
                <Form onSubmit={handleSubmit}>
                    <FormField>
                        <Label>Username:</Label>
                        <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    </FormField>
                    <FormField>
                        <Label>Email:</Label>
                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </FormField>
                    <FormField>
                        <Label>Password:</Label>
                        <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </FormField>
                    {error && <Error>{error}</Error>}
                    {success && <Success>{success}</Success>}
                    <Button type="submit">Signup</Button>
                </Form>
                <Text>
                    Already have an account? <StyledLink to="/login">Login here</StyledLink>
                </Text>
            </FormWrapper>
        </Container>
    );
};

export default Signup;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
`;

const FormWrapper = styled.div`
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormField = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    margin-bottom: 10px;
    font-weight: bold;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
`;

const Button = styled.button`
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background: #0056b3;
    }
`;

const Text = styled.p`
    margin-top: 20px;
`;

const StyledLink = styled(Link)`
    color: #007bff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const Error = styled.p`
    color: red;
    margin-bottom: 20px;
`;

const Success = styled.p`
    color: green;
    margin-bottom: 20px;
`;
