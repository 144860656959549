import React from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LandingPage = () => {
    return (
        <Container>
            <HeroSection>
                <HeroContent>
                    <HeroTitle>Take Your Betting to the Next Level</HeroTitle>
                    <HeroSubtitle>Grow your bankroll with state-of-the-art sports picks, professional analysts, and AI algorithms.</HeroSubtitle>
                    <ButtonGroup>
                        <PrimaryButton>Grow My Bankroll</PrimaryButton>
                        <SecondaryButton>FAQs</SecondaryButton>
                    </ButtonGroup>
                    <TrustNote>*25% of our customers do.</TrustNote>
                </HeroContent>
                <HeroImage src="/images/hero_betting_floor.png" alt="Hero Image" />
            </HeroSection>
            <AsSeenInSection>
                <AsSeenInTitle>As Seen In</AsSeenInTitle>
                <Logos>
                    <LogoImage src="/logos/TechCrunch-Logo-2011.png" alt="Logo 1" />
                    <LogoImage src="/logos/Wired-logo.png" alt="Logo 2" />
                    <LogoImage src="/logos/ESPN-logo.png" alt="Logo 3" />
                    {/* Add more logos as needed */}
                </Logos>
            </AsSeenInSection>
            <FeaturesSection>
                <Feature>
                    <FeatureTitle>Value-based picks</FeatureTitle>
                    <FeatureDescription>Our team of professionals analyzes thousands of games to find the highest value picks for your daily wagers.</FeatureDescription>
                    <FeatureImage src="/path/to/feature-image1.png" alt="Feature 1" />
                </Feature>
                <Feature>
                    <FeatureTitle>AI Line Shopping Bot</FeatureTitle>
                    <FeatureDescription>Our AI line shopping bot will seek out the best lines at the most popular sportsbooks and find the best values for your bets.</FeatureDescription>
                    <FeatureImage src="/path/to/feature-image2.png" alt="Feature 2" />
                </Feature>
                <Feature>
                    <FeatureTitle>Exclusive Insights</FeatureTitle>
                    <FeatureDescription>Get access to exclusive insights and strategies from our team of professional analysts.</FeatureDescription>
                    <FeatureImage src="/path/to/feature-image3.png" alt="Feature 3" />
                </Feature>
            </FeaturesSection>
            <TestimonialsSection>
                <TestimonialsTitle>Winners Circle</TestimonialsTitle>
                <Testimonial>
                    <TestimonialText>This site is amazing. So glad that I signed up. Can't wait to see great results! They will help improve your betting experience.</TestimonialText>
                    <TestimonialAuthor>Mark Augustin, <TestimonialEmail>hello@example.com</TestimonialEmail></TestimonialAuthor>
                </Testimonial>
                <Testimonial>
                    <TestimonialText>Been following numerous sports bettors and these guys are by far the best! They will enhance your betting strategy.</TestimonialText>
                    <TestimonialAuthor>Jane Doe, <TestimonialEmail>jane@example.com</TestimonialEmail></TestimonialAuthor>
                </Testimonial>
                {/* Add more testimonials as needed */}
            </TestimonialsSection>
            <FAQSection>
                <FAQTitle>Frequently Asked Questions</FAQTitle>
                <FAQItem>
                    <FAQQuestion>How is cryptocurrency made?</FAQQuestion>
                    <FAQAnswer>Learn your cryptocurrency facts with our complete guide to mining, staking, and other methods of earning cryptocurrency.</FAQAnswer>
                </FAQItem>
                {/* Add more FAQs as needed */}
            </FAQSection>
            <ContactSection>
                <ContactTitle>Contact Us</ContactTitle>
                <ContactForm>
                    <ContactInput type="text" placeholder="Full Name" />
                    <ContactInput type="email" placeholder="Email" />
                    <ContactButton>Send Message</ContactButton>
                </ContactForm>
                <SocialLinks>
                    <SocialLink href="https://linkedin.com">LinkedIn</SocialLink>
                    <SocialLink href="https://twitter.com">Twitter</SocialLink>
                    <SocialLink href="https://facebook.com">Facebook</SocialLink>
                    <SocialLink href="https://instagram.com">Instagram</SocialLink>
                </SocialLinks>
            </ContactSection>
            <Footer>
                <FooterText>&copy; 2024 BetPulse. All rights reserved.</FooterText>
                <FooterLinks>
                    <FooterLink href="/privacy">Privacy Policy</FooterLink>
                    <FooterLink href="/terms">Terms of Service</FooterLink>
                </FooterLinks>
            </Footer>
        </Container>
    );
};

export default LandingPage;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #0d0d0d;
    color: white;
    margin: 0;
    padding: 0;
`;

const HeroSection = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background-color: #121212;
    text-align: left;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 20px;
    }
`;

const HeroContent = styled.div`
    max-width: 60%;
    padding-right: 40px;

    @media (max-width: 768px) {
        max-width: 100%;
        padding-right: 0;
        text-align: center;
    }
`;

const HeroTitle = styled.h2`
    font-size: 48px;
    margin-bottom: 20px;
    padding-left: 40px;

    @media (max-width: 768px) {
        font-size: 36px;
    }
`;

const HeroSubtitle = styled.h3`
    font-size: 24px;
    margin-bottom: 20px;
    padding-left: 40px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 20px;
    padding-left: 40px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const PrimaryButton = styled.button`
    padding: 15px 30px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    font-size: 18px;

    &:hover {
        background-color: #0056b3;
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
        font-size: 16px;
    }
`;

const SecondaryButton = styled.button`
    padding: 15px 30px;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 18px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
        font-size: 16px;
    }
`;

const TrustNote = styled.p`
    padding-left: 40px;
    font-size: 14px;
    margin-top: 10px;
    color: #888;
`;

const HeroImage = styled.img`
    max-width: 60%;
    @media (max-width: 768px) {
        max-width: 100%;
        margin-top: 20px;
    }
`;

const AsSeenInSection = styled.section`
    padding: 40px;
    text-align: center;
    background-color: #0d0d0d;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const AsSeenInTitle = styled.h3`
    font-size: 24px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const Logos = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
`;

const LogoImage = styled.img`
    max-width: 200px;
    filter: invert(100%);

    @media (max-width: 768px) {
        max-width: 150px;
    }

    @media (max-width: 480px) {
        max-width: 100px;
    }
`;

const FeaturesSection = styled.section`
    padding: 40px;
    display: flex;
    justify-content: space-around;
    background-color: #1a1a1a;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Feature = styled.div`
    text-align: center;
    max-width: 300px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const FeatureTitle = styled.h4`
    font-size: 24px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const FeatureDescription = styled.p`
    font-size: 16px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const FeatureImage = styled.img`
    max-width: 100%;
`;

const TestimonialsSection = styled.section`
    padding: 40px;
    background-color: #121212;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const TestimonialsTitle = styled.h3`
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const Testimonial = styled.div`
    text-align: center;
    max-width: 600px;
    margin: 0 auto 20px auto;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const TestimonialText = styled.p`
    font-size: 16px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TestimonialAuthor = styled.p`
    font-size: 14px;
    color: #888;
`;

const TestimonialEmail = styled.span`
    font-size: 14px;
    color: #888;
`;

const FAQSection = styled.section`
    padding: 40px;
    background-color: #1a1a1a;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const FAQTitle = styled.h3`
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const FAQItem = styled.div`
    margin-bottom: 20px;
`;

const FAQQuestion = styled.h4`
    font-size: 18px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const FAQAnswer = styled.p`
    font-size: 16px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const ContactSection = styled.section`
    padding: 40px;
    text-align: center;
    background-color: #121212;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const ContactTitle = styled.h3`
    font-size: 24px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    margin: 0 auto;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const ContactInput = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 8px;
    }
`;

const ContactButton = styled.button`
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background: #0056b3;
    }

    @media (max-width: 768px) {
        padding: 8px;
        font-size: 14px;
    }
`;

const SocialLinks = styled.div`
    margin-top: 20px;
`;

const SocialLink = styled.a`
    color: white;
    text-decoration: none;
    margin: 0 10px;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Footer = styled.footer`
    padding: 20px;
    background-color: #0d0d0d;
    color: white;
    text-align: center;
`;

const FooterText = styled.p`
    margin: 0;
`;

const FooterLinks = styled.div`
    margin-top: 10px;
`;

const FooterLink = styled.a`
    color: white;
    text-decoration: none;
    margin: 0 10px;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;
