import React from 'react';
import styled from 'styled-components';

// Define the Pinnacle and Bovada logo paths
const pinnacleLogo = `${process.env.PUBLIC_URL}/logos/pinnacle_logo.png`;
const bovadaLogo = `${process.env.PUBLIC_URL}/logos/bovada_logo.png`;

const PinnacleOddsCellRenderer = ({ value, marketMaker }) => {
  // console.log('PinnacleOddsCellRenderer props.value:', value);
  // console.log('Market Maker:', marketMaker);

  // Split the value to handle main and reciprocal odds
  const lines = value.split('\n').map(line => ({ price: line.trim() }));

  // console.log('Lines:', lines);

  // Determine which logo to display
  const logo = marketMaker === 'bovada' ? bovadaLogo : pinnacleLogo;

  return (
    <CellContainer>
      {lines.map((line, idx) => (
        <LineContainer key={idx}>
          <span>{line.price}</span>
          <BookmakerLogo src={logo} alt={marketMaker} />
        </LineContainer>
      ))}
    </CellContainer>
  );
};

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const BookmakerLogo = styled.img`
  width: 20px; // Adjust size as needed
  height: 20px; // Adjust size as needed
  margin-left: 5px;
`;

export default PinnacleOddsCellRenderer;
