import React from 'react';
import styled from 'styled-components';

// Create a mapping of bookmaker keys to logo filenames
const bookmakerLogos = {
  draftkings: 'draftkings_logo.png',
  pinnacle: 'pinnacle_logo.png',
  bovada: 'bovada_logo.png',
  fanduel: 'fanduel_logo.png',
  williamhill_us: 'williamhill_us_logo.png',
  betmgm: 'betmgm_logo.png',
  betonlineag: 'betonlineag_logo.png',
  // Add other bookmakers and their logos here
};

const BestLineCellRenderer = (props) => {
  // console.log('BestLineCellRenderer props.value:', props.value);

  // Ensure props.value is correctly split into lines and bookmaker keys
  const lines = props.value.split('\n').map(line => {
    const [price, bookmakerKey] = line.split(' ');
    return { price, bookmakerKey: bookmakerKey?.replace(/[()]/g, '').toLowerCase() };
  });

  // console.log('Lines:', lines);

  return (
    <CellContainer>
      {lines.map((line, idx) => (
        line.bookmakerKey && (
          <LineContainer key={idx}>
            <span>{line.price}</span>
            <BookmakerLogo src={`${process.env.PUBLIC_URL}/logos/${bookmakerLogos[line.bookmakerKey]}`} alt={line.bookmakerKey} />
          </LineContainer>
        )
      ))}
    </CellContainer>
  );
};

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const BookmakerLogo = styled.img`
  width: 20px; // Adjust size as needed
  height: 20px; // Adjust size as needed
  margin-left: 5px;
`;

export default BestLineCellRenderer;
