import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const OddsCellRenderer = ({ value }) => {
//   console.log("ODDS CELL", value)

  return (
    <Container>
      {value && (
        <>
          {value.home_team && (
            <OddsRow>
              <OddsValue>
                {value.home_team.odds}
                {value.home_team.points && ` (${value.home_team.points})`}
              </OddsValue>
            </OddsRow>
          )}
          {value.away_team && (
            <OddsRow>
              <OddsValue>
                {value.away_team.odds}
                {value.home_team.points && ` (${value.away_team.points})`}
              </OddsValue>
            </OddsRow>
          )}
          {value.over && (
            <OddsRow>
              <OddsValue>
                {`${value.over.odds} (o ${value.over.points})`}
              </OddsValue>
            </OddsRow>
          )}
          {value.under && (
            <OddsRow>
              <OddsValue>
                {`${value.under.odds} (u ${value.under.points})`}
              </OddsValue>
            </OddsRow>
          )}
        </>
      )}

    </Container>
  );
};

export default OddsCellRenderer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the container takes full height */
  justify-content: center; /* Center children vertically */
`;

const OddsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
`;

const OddsValue = styled.div`
  flex: 1;
  text-align: right;
  transition: background-color 0.5s ease-in-out;
  &.highlight-green {
    background-color: #d4edda; /* Light green */
  }
  &.highlight-red {
    background-color: #f8d7da; /* Light red */
  }
`;