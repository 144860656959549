import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './ag-grid-theme-builder.css'; // Import custom dark theme CSS
// eslint-disable-next-line
import trackBetService from './services/trackBetService';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
import EventCellRenderer from './EventCellRenderer';
import TeamCellRenderer from './TeamCellRenderer';
import OddsCellRenderer from './OddsCellRenderer'; // Import the new renderer

const LogoHeader = ({ logoPath }) => (
    <div style={{ paddingLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <img src={logoPath} alt="Logo" style={{ height: '35px', borderRadius: '5px', alignContent: 'center', alignItems: 'center' }} />
    </div>
);

const SportOdds = ({ leagueKeys, title }) => {
    const [odds, setOdds] = useState([]);
    const [marketTypes, setMarketTypes] = useState([]);
    const [bookmakers, setBookmakers] = useState([]);
    const [marketFilter, setMarketFilter] = useState('');
    const [bookmakerFilter, setBookmakerFilter] = useState([]);

    useEffect(() => {
        const fetchOdds = async () => {
            try {
                const responses = await Promise.all(
                    leagueKeys.map(key => axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/odds/${key}/game`))
                );
                const mergedOdds = responses.flatMap(response => response.data || []);
                console.log("Fetched Odds:", mergedOdds);
                setOdds(mergedOdds);
                extractMarketTypesAndBookmakers(mergedOdds);
            } catch (error) {
                console.error('Error fetching odds:', error);
            }
        };

        fetchOdds();
        const intervalId = setInterval(fetchOdds, 5000);

        return () => clearInterval(intervalId);
    }, [leagueKeys]);

    const extractMarketTypesAndBookmakers = (data) => {
        const marketTypesSet = new Set();
        const bookmakersSet = new Set();
        data.forEach(eventItem => {
            if (eventItem.eventGameOdds?.bookmakers) {
                eventItem.eventGameOdds.bookmakers.forEach(bookmaker => {
                    bookmakersSet.add(bookmaker.key);
                    if (bookmaker.markets) {
                        bookmaker.markets.forEach(market => {
                            marketTypesSet.add(market.key);
                        });
                    }
                });
            }
        });
        const marketTypesArray = [...marketTypesSet];
        const bookmakersArray = [...bookmakersSet].map(bm => ({ value: bm, label: bm }));
        setMarketTypes(marketTypesArray);
        setBookmakers(bookmakersArray);
        setMarketFilter(prev => prev || (marketTypesArray.length > 0 ? marketTypesArray[0] : ''));
    };

    const handleMarketFilterChange = (value) => {
        setMarketFilter(value);
    };

    const handleBookmakerFilterChange = (value) => {
        setBookmakerFilter(value || []);
    };

    const filterByMarketAndBookmaker = useCallback((oddsItem) => {
        if (marketFilter === '' && bookmakerFilter.length === 0) return true;
        const selectedBookmakers = bookmakerFilter.map(bm => bm.value);
        return oddsItem.eventGameOdds?.bookmakers?.some(bookmaker =>
            (bookmakerFilter.length === 0 || selectedBookmakers.includes(bookmaker.key)) &&
            bookmaker.markets?.some(market => market.key === marketFilter)
        );
    }, [marketFilter, bookmakerFilter]);

    const columns = useMemo(() => {
        if (odds.length === 0) {
            return [];
        }

        const commonColumns = [
            {
                headerName: 'Event',
                field: 'event',
                cellRenderer: 'EventCellRenderer',
                maxWidth: 300,
                valueGetter: params => ({ event: params.data.event, outcomes: params.data.outcomes }), // Ensure correct structure
            },
            {
                headerName: 'Teams',
                field: 'teams',
                maxWidth: 200,
                cellRenderer: 'TeamCellRenderer',
                valueGetter: params => ({ home_team: params.data.event.home_team, away_team: params.data.event.away_team }), // Ensure correct structure
            },
        ];

        const selectedBookmakers = bookmakerFilter.map(bm => bm.value);
        const filteredBookmakers = odds[0].eventGameOdds?.bookmakers?.filter(bookmaker =>
            bookmakerFilter.length === 0 || selectedBookmakers.includes(bookmaker.key)
        ) || [];

        const bookmakerColumns = filteredBookmakers.map(bookmaker => ({
            headerComponent: LogoHeader,
            headerComponentParams: {
                logoPath: `/logos/${bookmaker.key}_logo.png`
                    || `/logos/${bookmaker.key}_logo.jpeg`
            },
            field: `outcomes.${bookmaker.key}`,
            cellRenderer: 'OddsCellRenderer',
            maxWidth: 90,
            valueGetter: params => {
                const outcomes = params.data.outcomes[bookmaker.key];
                if (!outcomes) return null;

                // Dynamically construct the value object for OddsCellRenderer
                let value = {};
                value["home_team"] = []
                value["away_team"] = []
                
                Object.entries(outcomes).forEach(([outcomeName, outcomePrice]) => {
                    if (outcomeName.includes('_points')) return; // Skip points entry
                    console.log(`Outcome Name: ${outcomeName}`); // Log the outcome name

                    if (outcomeName === params.data.event.home_team) {
                        value["home_team"] = {
                            odds: outcomePrice,
                            points: outcomes[outcomeName + '_points'] // Assuming points are stored with the same naming convention
                        };
                    } else if (outcomeName === params.data.event.away_team) {
                        value["away_team"] = {
                            odds: outcomePrice,
                            points: outcomes[outcomeName + '_points'] // Assuming points are stored with the same naming convention
                        };
                    } else if (outcomeName === "Over") {
                        value["over"] = {
                            odds: outcomePrice,
                            points: outcomes[outcomeName + '_points'] // Assuming points are stored with the same naming convention
                        };
                    } else if (outcomeName === "Under") {
                        value["under"] = {
                            odds: outcomePrice,
                            points: outcomes[outcomeName + '_points'] // Assuming points are stored with the same naming convention
                        };
                    }
                    // console.log(`Setting odds for ${outcomeName}: ${outcomePrice}`); // Log the odds
                });


                return value;
            }
        }));


        return [...commonColumns, ...bookmakerColumns];
    }, [odds, marketFilter, bookmakerFilter]);

    const rowData = useMemo(() => {
        const eventMap = new Map();

        odds.filter(filterByMarketAndBookmaker).forEach(oddsItem => {
            const eventKey = `${oddsItem.event.home_team} vs ${oddsItem.event.away_team} (${oddsItem.event.commence_time})`;

            if (!eventMap.has(eventKey)) {
                eventMap.set(eventKey, {
                    event: oddsItem.event,
                    outcomes: {}
                });
            }

            const eventData = eventMap.get(eventKey);

            oddsItem.eventGameOdds.bookmakers.forEach(bookmaker => {
                bookmaker.markets.forEach(market => {
                    if (market.key === marketFilter) {
                        market.outcomes.forEach(outcome => {
                            if (!eventData.outcomes[bookmaker.key]) {
                                eventData.outcomes[bookmaker.key] = {};
                            }
                            eventData.outcomes[bookmaker.key][outcome.name] = outcome.price;
                            eventData.outcomes[bookmaker.key][outcome.name + '_points'] = outcome.point; // Adding points to the outcomes
                        });
                    }
                });
            });

        });

        // console.log("Processed RowData:", Array.from(eventMap.values())); // Add this line to log the processed rowData
        return Array.from(eventMap.values());
    }, [odds, marketFilter, filterByMarketAndBookmaker]);

    const autoSizeStrategy = {
        type: 'fitCellContents'
    };

    return (
        <Container>
            <Header>
                <Title>{title}</Title>
                <FilterContainer>
                    <select value={marketFilter} onChange={(e) => handleMarketFilterChange(e.target.value)}>
                        {marketTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                    <Select
                        isMulti
                        value={bookmakerFilter}
                        onChange={handleBookmakerFilterChange}
                        options={bookmakers}
                        placeholder="Select Bookmakers"
                        styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                    />
                </FilterContainer>
            </Header>
            <div className="ag-theme-custom" style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                    autoSizeStrategy={autoSizeStrategy}
                    components={{ EventCellRenderer, TeamCellRenderer, OddsCellRenderer }} // Add OddsCellRenderer
                    rowData={rowData}
                    columnDefs={columns}
                    rowHeight={75} // Set static row height here
                    domLayout='autoHeight'
                    suppressMovableColumns={true}
                    defaultColDef={{
                        flex: 1,
                        sortable: false,
                        resizable: true,
                        filter: false, // Enable default filters
                    }}
                />
            </div>
        </Container>
    );
};

export default SportOdds;

const Container = styled.div`
    padding: 20px;
    background-color: #1F2836;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 2px solid #333;
`;

const Title = styled.h2`
    margin: 0;
    font-size: 24px;
    color: #fff;
`;

const FilterContainer = styled.div`
    display: flex;
    gap: 10px;
`;
