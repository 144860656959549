// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, requiredAccessLevel, ...rest }) => {
  const token = localStorage.getItem('token');
  const accessLevel = localStorage.getItem('accessLevel');

  if (!token) {
    return <Navigate to="/login" />;
  }

  const requiredAccessLevels = requiredAccessLevel.split(',').map(level => level.trim());
  const hasAccess = requiredAccessLevels.includes(accessLevel);

  return hasAccess ? <Component {...rest} /> : <Navigate to="/unauthorized" />;
};

export default PrivateRoute;
