import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const TrackedBets = () => {
    const [trackedBets, setTrackedBets] = useState([]);
    const [liveOdds, setLiveOdds] = useState({});

    useEffect(() => {
        const fetchTrackedBets = async () => {
            try {
                const userId = localStorage.getItem('user_id');
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/tracked-bets/${userId}`);
                setTrackedBets(response.data);

                // Fetch live odds for each tracked bet
                response.data.forEach(bet => {
                    fetchLiveOdds(bet);
                });
            } catch (error) {
                console.error('Error fetching tracked bets:', error);
            }
        };

        fetchTrackedBets();
    }, []);

    const fetchLiveOdds = async (bet) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/odds/${bet.sport_key}/game`);
            const eventOdds = response.data.find(event => event.event.id === bet.event_id);

            if (eventOdds) {
                for (const bookmaker of eventOdds.eventGameOdds.bookmakers) {
                    if (bookmaker.key === 'draftkings') {
                        const market = bookmaker.markets.find(market => market.key === bet.market_key);
                        if (market) {
                            const outcome = market.outcomes.find(outcome => outcome.name === bet.outcome_name);
                            if (outcome) {
                                setLiveOdds(prevState => ({
                                    ...prevState,
                                    [bet.id]: outcome.price
                                }));
                                break;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching live odds:', error);
        }
    };

    const handleRemoveBet = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/tracked-bets/${id}`);
            setTrackedBets(trackedBets.filter(bet => bet.id !== id));
            setLiveOdds(prevState => {
                const newState = { ...prevState };
                delete newState[id];
                return newState;
            });
        } catch (error) {
            console.error('Error removing tracked bet:', error);
        }
    };

    return (
        <Container>
            <Title>Tracked Bets</Title>
            <Table>
                <thead>
                    <tr>
                        <Th>Sport</Th>
                        <Th>Event</Th>
                        <Th>Market</Th>
                        <Th>Outcome</Th>
                        <Th>DraftKings Taken Odds</Th>
                        <Th>Live Odds</Th>
                        <Th>Action</Th>
                    </tr>
                </thead>
                <tbody>
                    {trackedBets.length === 0 ? (
                        <tr>
                            <Td colSpan="7">No tracked bets found</Td>
                        </tr>
                    ) : (
                        trackedBets.map((bet, index) => (
                            <tr key={index}>
                                <Td>{bet.sport_key}</Td>
                                <Td>{bet.event_id}</Td>
                                <Td>{bet.market_key}</Td>
                                <Td>{bet.outcome_name}</Td>
                                <Td>{bet.draftkings_taken_odds}</Td>
                                <Td>{liveOdds[bet.id] !== undefined ? liveOdds[bet.id] : 'Loading...'}</Td>
                                <Td>
                                    <RemoveButton onClick={() => handleRemoveBet(bet.id)}>Remove</RemoveButton>
                                </Td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </Container>
    );
};

export default TrackedBets;

const Container = styled.div`
    padding: 20px;
    background-color: #f8f9fa;
`;

const Title = styled.h2`
    margin: 0;
    font-size: 24px;
    color: #343a40;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    th, td {
        padding: 12px 15px;
        text-align: left;
    }

    thead {
        background-color: #343a40;
        color: white;
    }

    tbody tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    tbody tr:nth-child(odd) {
        background-color: #ffffff;
    }

    tbody tr:hover {
        background-color: #e9ecef;
    }
`;

const Th = styled.th`
    position: sticky;
    top: 0;
    background: #343a40;
    color: white;
    z-index: 2;
`;

const Td = styled.td``;

const RemoveButton = styled.button`
    padding: 5px 10px;
    background-color: #ff0000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #cc0000;
    }
`;
