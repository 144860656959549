import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

const Login = () => {
    const [identifier, setIdentifier] = useState(''); // Can be username or email
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, { identifier, password });
            console.log('Login successful:', response.data);
            localStorage.setItem('token', response.data.token); // Store token in local storage
            localStorage.setItem('accessLevel', response.data.user.accessLevel); // Store accessLevel in local storage
            localStorage.setItem('user_id', response.data.user.id); // Store user_id in local storage
            navigate('/mlb'); // Redirect to MLB dashboard
        } catch (error) {
            setError('Login failed. Please check your credentials and try again.');
            console.error('Error logging in:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <Container>
            <FormWrapper>
                <h2>Login</h2>
                <Form onSubmit={handleSubmit}>
                    <FormField>
                        <Label>Username or Email:</Label>
                        <Input type="text" value={identifier} onChange={(e) => setIdentifier(e.target.value)} required />
                    </FormField>
                    <FormField>
                        <Label>Password:</Label>
                        <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </FormField>
                    {error && <Error>{error}</Error>}
                    <Button type="submit">Login</Button>
                </Form>
                <Text>
                    Don't have an account? <StyledLink to="/signup">Signup here</StyledLink>
                </Text>
                <Text>
                    Forgot your password? <StyledLink to="/forgot-password">Reset it here</StyledLink>
                </Text>
            </FormWrapper>
        </Container>
    );
};

export default Login;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
`;

const FormWrapper = styled.div`
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormField = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    margin-bottom: 10px;
    font-weight: bold;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
`;

const Button = styled.button`
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background: #0056b3;
    }
`;

const Text = styled.p`
    margin-top: 20px;
`;

const StyledLink = styled(Link)`
    color: #007bff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const Error = styled.p`
    color: red;
    margin-bottom: 20px;
`;
