// PlayerPropsCellRenderer.js
import React from 'react';
import styled from 'styled-components';

const PlayerPropsCellRenderer = ({ value }) => {
    if (!value) return null;

    return (
        <Container>
            {value.split(', ').map((item, index) => (
                <div key={index}>{item}</div>
            ))}
        </Container>
    );
};

export default PlayerPropsCellRenderer;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
