import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Unauthorized = () => {
  return (
    <Container>
      <h1>Unauthorized</h1>
      <p>You do not have access to this page.</p>
      <Link to="/login">Login</Link>
    </Container>
  );
};

export default Unauthorized;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
